.header {
  background-color: #208049;
  padding: 0px 1rem;
  height: auto;
}

.header nav {
  height: 100%;
}

.header ul {
  height: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.header a {
  color: white;
  text-decoration: none;
  font-size: 1.777rem;
}

.header a:hover {
  color: #fd9801;
}
.header a:active,
.header a.active {
  color: #ffac31;
}

.homeLink {
  margin-right: auto;
  /* align-self: flex-start; */
}

.homeLink a {
  font-size: 3.157rem;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px; /* This is for construction text, must be deleted later */
}

.menu {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.construction {
  background-color: red;
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.construction p {
  color: white;
  text-align: center;
  font-size: 20px;
}
